.form__container{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    .login_form{
        width: 100%;
        display: flex;
        flex-direction: column;
        place-self: center;
        .form__header{
            text-align: center;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            .logo{
                margin-bottom: 23px;
                max-width: 126px;
                height: 124px;
                width: 100%;
            }
            .title{
                margin-top: 23px;
                font: normal normal normal 40px/46px Ubuntu;
                letter-spacing: 0px;
                color: #575757;
                opacity: 1;
                margin-bottom: 15px;
            }
            .caption{
                font: normal normal normal 25px/29px Ubuntu;
                letter-spacing: 0px;
                color: #575757;
                opacity: 1;
                margin-bottom: 69px;
            }
        }
        .form__body{
            margin-top: 10px;
            text-align:center;
            display: flex;
            flex-direction: column;
            gap: 21px;
            .input__login{
                max-width: 500px;
                margin: 0 auto;
                width: 100%;
                font-size: 14px;
                background: #EAEDF0 0% 0% no-repeat padding-box;
                border-radius: 10px;
                opacity: 1; 
                position: relative;
                height: 60px;
                .icoon__password{
                    height: 100% !important;
                    font-size: 10px;
                }
            }
            Input{
                height: 100%;
            }
            .svgContainer{
                position: absolute;
                right: 0;
                padding: 10px;
            }
            .button{
                max-width: 500px;
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .form__forgot{
                max-width: 500px;
                width: 100%;
                margin: 0 auto;
                margin-bottom: 48px;
                .forgot{
                    text-align: right;
                    font: normal normal normal 15px/17px Ubuntu;
                    color: #E93423;
                    opacity: 1;
                }
            }
        }
    }
    .image{
        width: 100%;
    }
}