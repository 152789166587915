.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
.table__container{
    padding: 0 53px;
    .thead {
        
        Tr{
            Th{
                font-size: 16px;
                letter-spacing: 0px;
                text-transform: capitalize;
                color: #E2E2E2;
                opacity: 1;
            }
        } 
    }
    .tbody{
        Tr{
            Td{
                font-size: 16px;
                letter-spacing: 0px;
                color: #3C3C3C;
                opacity: 1;
            }
        }
    }
}
.card_header{
    padding: 40px 53px 33px 53px !important;
    
    .heading{
        font-family: Ubuntu, medium;
        font-size: 20px;
        letter-spacing: 1.5px;
        color: #E93423;
        text-transform: uppercase;
        opacity: 1;
    }
    .text{
        font-family: Ubuntu, medium;
        font-size: 15px;
        letter-spacing: 1.5px;
        color: #969696;
        text-transform: uppercase;
        opacity: 1;
    }
}
.active{
    font-weight: bold !important;
}