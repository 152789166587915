.upperLeft__img{
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px 3px 15px #A8A5E35F;
    border-radius: 15px;
    opacity: 0.46;

}
.image__text{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    .title{
        font: normal normal medium 20px/24px Ubuntu;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }
    .number{
        font: normal normal bold 69px/79px Ubuntu;
        letter-spacing: 3.45px;
        text-transform: uppercase;
        display: flex;
        gap: 20px;
        align-self: center;
        align-items: center;
    }
}
.greet{
    font-family: Ubuntu, Medium;
    font-size: 30px;
    letter-spacing: 2.25px;
    color: #E93423;
    text-transform: uppercase;
    opacity: 1;
}
.render__page{
    font-family: Ubuntu, Medium;
    font-size: 15px;
    letter-spacing: 1.13px;
    color: #E93423;
    text-transform: uppercase;
    opacity: 1;
    span{
        color: #A7A7A7;
    }
}
.btn{
    font-family: Ubuntu, Medium;
    font-size: 15px;
    letter-spacing: 1.13px;
    color: #FFFFFF;
    text-transform: uppercase;
    padding: 23px;
}
.color__yellow{
    font-family: Ubuntu, Medium;
    color: #FFC21D;
    opacity: 1;
}
.color__green{
    font-family: Ubuntu, Medium;
    color: #21CE50;
    opacity: 1;
}
.color__red{
    font-family: Ubuntu, Medium;
    color: #E93423;
    opacity: 1;
}
.card{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #A8A5E35F;
    border-radius: 15px;
    opacity: 1;
    .cardBody{
        padding: 56px 34px 61px 35px;
        .heading{
            font-family: Ubuntu, Medium;
            font-size: 18px;
        }
        .text{
            font-size: 54px;
            font-family: Ubuntu, Medium;
            text-align: center;
        }
    }
}