.InputRight {
    right: 0 !important;
    left: auto !important;
    height: 100% !important;
}
.Input{
    width: 392px !important;
    padding: 10px !important;
    height: 60px !important;
    
}
.card{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #A8A5E35F !important;
    border-radius: 15px !important;
    opacity: 1;
    
}