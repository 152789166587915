.icon__merchant{
    margin-left: 20px;
}
Select{
    color: red;
    .dropdown{
        background: #EAEDF0 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        align-items: center;
        font-family: Ubuntu, medium;
        font-size: 15px;
        letter-spacing: 1.13px;
        opacity: 1;
    }
}
.chakra-select__icon{
    color: red !important;
}