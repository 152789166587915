@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F4F7 !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
