
.generate{
    .card{
        max-width: 1217px;
        width: 100%;
        position: relative;
    }
}
.btn{
    Button{
        padding: 22px 18px;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 1.13px;
        color: #FFFFFF;
        text-transform: uppercase;
        opacity: 1;
        .icon{
            margin-right: 20px;
        }
    }
    .cancel{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        color: #E93423;
    }
}