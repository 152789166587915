.file{
    background: #EAEDF0 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    position: relative;
}
.ViewUpload{
    width: 60%;
  margin: auto;
  display: block;
  img{
    margin: 0 auto;
  }
  .Add{
    .card{
        width: 100%;
        position: relative;
        height: 484px;
    }
}
        .flex{
            align-items: center !important;
            justify-content: space-between;
            .input{
                width: 370px;
                height: 60px;     
            }
            .select{
                background: #EAEDF0 0% 0% no-repeat padding-box;
                border-radius: 10px;
                opacity: 1;
                height: 60px;
            }
            .css-42b2qy {
                width: 370px;
            }
        }
    Input{
        background: #EAEDF0 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        padding: 10px;
        font-size: 15px;
    }
}
p{
    font-size: 15px;
}
.FormLabel{
    font-size: 17px;
}
.btn{
    position: absolute;
    left: 44px;
}
.input{
    background: #EAEDF0 !important ;
    border-radius: 10px;
    opacity: 1;
    width: 370px !important;
}
.flex{
    align-items: center;
}