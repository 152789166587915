.Add{
    .card{
        width: 100%;
        position: relative;
        height: 484px;
    }
}
.card__container{
    padding: 0px 50px 36px 50px;
    .FormControl{
        .flex{
            align-items: center;
            justify-content: space-between;
            .input{
                width: 370px;
                height: 60px;     
            }
            .select{
                background: #EAEDF0 0% 0% no-repeat padding-box;
                border-radius: 10px;
                opacity: 1;
                height: 60px;
            }
            .css-42b2qy {
                width: 370px;
            }
        }
    }
    Input{
        background: #EAEDF0 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        padding: 10px;
        font-size: 15px;
    }
}
p{
    font-size: 15px;
}
.FormLabel{
    font-size: 17px;
}