.header__container{
    background-color: #FFFFFF;
    .header__left{
            .img{
                width: 100%;
                max-width: 62px;
                height: 60px;
            }
            .logo__title{
                width: 230px;
                font: normal normal 600 20px/24px Ubuntu;
                letter-spacing: 2px;
                color: #E93423;
                text-transform: uppercase;
                opacity: 1;
            }
        }
    .header__right{
        .welcome{
            font: normal normal 600 18px/21px Ubuntu;
            letter-spacing: 1.35px;
            color: #E93423;
            text-transform: uppercase;
            opacity: 1;
        }
        
    }
    
}
.navbar{
    background: #E93423 0% 0% no-repeat padding-box;
    nav__container{
       height: 100%;
       justify-content: center; 
       HStack{
        gap: 13px !important;
       }
       nav{
        font: normal normal medium 15px/17px Ubuntu;
        letter-spacing: 1.13px;
        color: #FFFFFF;
        text-transform: uppercase;
        opacity: 1;
        padding: 29px 0;
       }
    }
}
@media only screen and (max-width: 1600px) {
    .header__container{
        background-color: #FFFFFF;
        .header__left{
                .img{
                    max-width: 50px;
                    height: 50px;
                }
                .logo__title{
                    width: 200px;
                    font-weight: 600;
                    font-size: small;
                    letter-spacing: 1.5px;
                    color: #E93423;
                    text-transform: uppercase;
                    opacity: 1;
                }
            }
        .header__right{
            .welcome{
                font-weight: 600;
                font-size: small;
                letter-spacing: 1.5px;
                color: #E93423;
                text-transform: uppercase;
                opacity: 1;
            }
            
        }
        
    }
    .navbar{
        nav__container{
           justify-content: center; 
           nav{
            font: normal normal medium 15px/17px Ubuntu;
            letter-spacing: 1.13px;
            color: #FFFFFF;
            text-transform: uppercase;
            opacity: 1;
            padding: 0px 0;
           }
        }
    }
  }