.view__active{
    .card{
        width: 100%;
        .heading{
            letter-spacing: 1.5px;
            color: #E93423;
            text-transform: uppercase;
            opacity: 1;
            font-size: 20px;
            margin-bottom: 30px;
        }
    }
}