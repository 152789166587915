.card__container{
    padding: 0px 50px 36px 50px;
    
    Input{
        background: #EAEDF0 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        padding: 10px;
        font-size: 15px;
        height: 60px;
    }
    .FormLabel{
        font: normal normal medium 15px/17px Ubuntu !important;
        letter-spacing: 1.13px;
        color: #3C3C3C;
        opacity: 1;
    }
}
.generate__btn{
    background: #E93423 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    padding: 22px !important;
    gap: 12px;
    font: normal normal medium 15px/17px Ubuntu !important;
    letter-spacing: 1.13px;
    color: #FFFFFF !important;
    text-transform: uppercase;
    opacity: 1;
}
.cancel__generate{
    border-radius: 10px;
    opacity: 1;
    padding: 22px !important;
    gap: 12px;
    font: normal normal medium 15px/17px Ubuntu !important;
    letter-spacing: 1.13px;
    text-transform: uppercase;
    color: #E93423 !important;
    opacity: 1;
}
