.upload{
    width: 216px;
    height: 220px;
    /* UI Properties */
    background: #EAEDF0 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 0 0 0 26px;
    background-image: url('../../../../assets/svg/upload.svg');
    background-position: center;
    position: relative;
    
}
.upload::after{
    content: "Upload File";
    position: absolute;
    bottom: 44px;
    /* left: 0; */
    /* right: 0; */
    width: 100%;
    text-align: center;
}
@media only screen and (max-width: 1600px) {
    .upload{
        width: 150px;
        height: 150px;
        /* UI Properties */
        background: #EAEDF0 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        margin: 0 0 0 10px;
        background-image: url('../../../../assets/svg/upload.svg');
        background-position: center;
        position: relative;
        
    }
    .upload::after{
       font-size: 15px;
       margin-top: 2rem;
       bottom: 24px;
    }
   
  }